import React from "react"

import { ReactComponent as GooglePlay } from "../assets/google-play-badge_clean.svg"
import { ReactComponent as AppStore } from "../assets/apple_app_store_badge.svg"

import './AppStores.css'

class AppStores extends React.Component {
  render = () =>
    <div className={'appStoresContainer'}>
      <span className={'appStoresLabel'}>
        También disponible en:
      </span>
      <div className={'appStoresBadgesContainer'}>
        <a className={'appStoresBadge'} href='https://apps.apple.com/es/app/recitaleyes/id6474899189'>
          <AppStore alt='Disponible en App Store' />
        </a>
        <a className={'appStoresBadge'} href='https://play.google.com/store/apps/details?id=com.recitaleyes.app'>
          <GooglePlay alt='Disponible en Google Play' />
        </a>
      </div>
    </div>
}

export default AppStores