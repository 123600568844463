import React from "react"

import { ReactComponent as EarthIcon } from "../assets/earth2.svg"
import { ReactComponent as DevicesIcon } from "../assets/cellphone-link.svg"
import { ReactComponent as LawIcon } from "../assets/scale-balance.svg"
import { ReactComponent as NotesIcon } from "../assets/notebook-outline.svg"
import { ReactComponent as UpToDateIcon } from "../assets/cached.svg"
import { ReactComponent as PiggyBank } from "../assets/piggy-bank-outline.svg"
import config from "../config"

import './Features.css'

class Features extends React.Component {

  feature = (Icon, text, altText) => {
    return <div className='featureContainer'>
      <Icon className='featuresIcon' alt={altText}/>
      <div className='featuresText'>
        { text }
      </div>
    </div>
  }

  render = () =>
    <div id="features" className={'mainCard cardShadow featuresContainer'}>
      <div className='featuresFlexContainer'>
        {
          this.feature(EarthIcon, 'Llévate tus leyes y tus apuntes contigo y estudia donde y cuando quieras', 'Icono del planeta tierra')
        }
        {
          this.feature(DevicesIcon, 'Desde cualquier dispositivo, ya sea ordenador, tableta o móvil', 'Icono multidispositivo')
        }
        {
          this.feature(UpToDateIcon, 'Audioleyes siempre actualizadas a la última versión consolidada', 'Icono de contenido siempre actualizado')
        }
        {
          this.feature(LawIcon, 'Más de 300 leyes listas para escuchar... Si nos falta alguna, ¡LA AÑADIMOS!', 'Icono de leyes')
        }
        {
          this.feature(NotesIcon, 'Deja que Recitaleyes lea tus temas: completos o resumidos', 'Icono de libreta de apuntes')
        }
        {
          this.feature(PiggyBank, '¡Completamente gratis!', 'Icono de hucha')
        }
      </div>
      <div className='featuresCallToAction'>
        <a className={'rcl'} href={config.APP_SIGNUP_URL}>
          Empieza GRATIS
        </a>
      </div>
    </div>
}

export default Features