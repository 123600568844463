/*
  TODO (MVP)
    - Legal
      - Política de cookies

  TODO (mejoras)
    - Mejorar textos
    - Revisar textos
 */

import React from 'react'

import Header from './components/Header'
import Background from './components/Background'
import Presentation from "./components/Presentation"
import Description from "./components/Description"
import Features from "./components/Features"
import Faq from './components/Faq'
import Footer from "./components/Footer"
import AppStores from "./components/AppStores"

import './App.css'
import Whatsapp from "./components/Whatsapp"
//import SaleModal from "./components/SaleModal"


class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scrolled: false,
      freeCoupon: false,
    }
  }

  componentDidMount = () => {
    window.onscroll = this.setScroll
    setTimeout(() => this.setState({freeCoupon: true}), 5000)
  }

  setScroll = (e) => this.setState({ scrolled: (e.target.scrollingElement.scrollTop > 0) })

  render = () =>
    <div className="App">
      <Header scrolled={ this.state.scrolled }/>
      <Background />
      <Presentation />
      <Description />
      <Features />
      <Faq />
      <Footer />
      <AppStores />
      <Whatsapp />
    </div>
}

export default App