import React from "react"
import './Background.css'

class Background extends React.Component {
  render = () =>
    <div className={'backgroundContainer'}>
      { this.props.children }
    </div>
}

export default Background