import config from '../config'

const es = {
  FAQ: [
    {
      q: '¿Cómo me registro en Recitaleyes?',
      a: 'En la parte superior de esta página, a la derecha, tienes el botón "Acceder", a través de cual podrás acceder a la aplicación web y, si todavía no te has registrado, podrás hacerlo de forma completamente gratuita.'
    },
    {
      q: 'No encuentro una de las leyes de mi oposición en Recitaleyes',
      a: 'Si necesitas una ley y todavía no la tenemos en Recitaleyes, no dudes en pedírnosla a través del formulario de contacto, ¡trataremos de tenerla disponible en menos de 72h!'
    },
    {
      q: '¿Qué tipo de normas están disponibles para escuchar en Recitaleyes?',
      a: 'En Recitaleyes puedes encontrar cualquier texto normativo que haya sido publicado en el Boletín Oficial del Estado (BOE) o el Diario Oficial de la UE (EurLex): leyes, leyes orgánicas, reales decretos, etc.'
    },
    {
      q: '¿Qué versión de cada norma está disponible en Recitaleyes?',
      a: 'Los textos legales disponibles en Recitaleyes son siempre los textos consolidados elaborados y publicados por el BOE o EurLex. Es decir, que siempre será legislación actualizada y consolidada con los cambios de cualquier modificación que haya sido publicada.'
    },
    {
      q: 'Si el legislador modifica una norma, ¿cuánto tarda en actualizarse en Recitaleyes?',
      a: 'Actualizamos las normas en cuanto el diario o boletín oficial correspondiente publica la versión consolidada de la norma. Por ejemplo, si se publica una modificación del Código Civil, en cuanto el BOE publica la versión consolidada nosotros actualizamos nuestros audios. Desde la aprobación de la modificación por el legislador, este proceso no suele demorar más de 3 días.'
    },
    {
      q: '¿Puedo escuchar mis propios apuntes?',
      a: '¡Claro! Podrás leer y escuchar tu propio temario desde la sección "Mis apuntes" que encontrarás una vez accedas a la aplicación web.'
    },
    {
      q: 'Mi temario es muy extenso, ¿podré subirlo entero a Recitaleyes?',
      a: 'Sí, puedes subir todos los temas que necesites.'
    },
    {
      q: 'Necesito ayuda, ¿cómo puedo contactar con el soporte de Recitaleyes?',
      a: `Puedes contactar con nosotros a través del formulario de contacto o enviándonos un email a ${config.CONTACT_EMAIL}.`
    },
    {
      q: 'Tengo una academia o soy preparador, ¿puedo ofrecer nuestro temario a mis alumnos o integrar Recitaleyes en mi aula virtual?',
      a: '¡Sí! Si eres preparador o tienes una academia, puedes ponerte en contacto con nosotros para poder ofrecer a tus alumnos tu temario en audio con la tecnología de Recitaleyes. Si tienes un aula virtual, puedes contactar con nosotros para estudiar la posibilidad de integrar Recitaleyes directamente en ella.'
    },
    {
      q: '¿En qué dispositivos podré escuchar mis leyes y mis apuntes?',
      a: 'El reproductor de Recitaleyes es compatible con la mayor parte de ordenadores, portátiles, tablets y móviles.'
    },
    {
      q: '¿Cómo es la voz de los audios de Recitaleyes?',
      a: 'Utilizamos las Inteligencias Artificuales más avanzadas que existen para convertir los textos de las normas en una voz realista y humana, lo que hace que nuestros audios tengan un ritmo y una entonación naturales y cómodos, ayudándote a comprender y memorizar mejor los textos. Además, a diferencia de una lectura basada en grabaciones de personas, ¡nuestra IA no se cansa! Por lo que el tono de la lectura es siempre estable y natural: y esta es una de nuestras mayores ventajas, ya que ¡la entonación es al audio lo que los colores a unos apuntes! ¡Notarás la diferencia!'
    },
    {
      q: '¿Hay algún contenido que no se pueda reproducir?',
      a: 'Al menos por el momento, sí. Las tablas se omiten de los audios de Recitaleyes, ya que su estudio mediante audio no es eficaz. Así mismo, aunque sucede en muy pocos casos, tampoco se reproducen las imágenes, por razones evidentes ;).'
    }
  ],

  MONTHS: ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],

  LEGAL_LOPD_FIRST_LAYER_CONTACT: 'Estos datos solo serán utilizados por Recitaleyes para atender tu consulta. Conoce tus derechos en nuestra ',
}

export default es