const config = {
  APP_SIGNUP_URL: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_RECITALEYES_APP_URL}/#signUp`,
  APP_URL: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_RECITALEYES_APP_URL}/`,
  API_URL: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_RECITALEYES_API_URL}`,
  CONTACT_URL: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_RECITALEYES_AUTH_URL}/contact`,
  COUPON_URL: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_RECITALEYES_AUTH_URL}/coupon`,
  WHATSAPP: process.env.REACT_APP_RECITALEYES_WHATSAPP,

  DEMO_TOKEN: process.env.REACT_APP_DEMO_TOKEN,

  MAX_CONTACT_MESSAGE_LENGTH: 5000,
  CONTACT_EMAIL: 'hola@recitaleyes.com',
}

export default config