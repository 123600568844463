import React from "react"
import demoAudioFile from '../assets/CE_demo_article1.mp3'
import config from '../config'

import Spinner from "./Spinner"
import LawList from './LawList'
import {ReactComponent as PlayIcon} from "../assets/play.svg"
import {ReactComponent as PauseIcon} from "../assets/pause.svg"

import './Description.css'

class Description extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      loading: false,
      showLawList: false
    }
  }

  demoAudio = null

  setupAudioDemo = () => {
    this.demoAudio = new Audio(demoAudioFile)
    this.demoAudio.onwaiting = () => this.setState({ playing: false, loading: true })
    this.demoAudio.onplaying = () => this.setState({ playing: true, loading: false })
    this.demoAudio.onpause = () => {
      this.demoAudio.currentTime = 0
      this.setState({ playing: false, loading: false })
    }
    this.demoAudio.onplay = () => this.setState({ playing: true, loading: false })
    this.demoAudio.onended = () => this.setState({ playing: false, loading: false })

    this.demoAudio.play()
  }

  togglePlayDemo = () => {
    if (!this.demoAudio && !this.state.loading) this.setupAudioDemo()
    else this.demoAudio.paused ? this.demoAudio.play() : this.demoAudio.pause()
  }

  toggleLawList = () => this.setState({showLawList: !this.state.showLawList})

  render = () =>
    <div id="description" className='mainCard cardShadow descriptionCard'>
      <div className='descriptionContainer'>
        <div className='descriptionHeader'>
          Estudia escuchando y leyendo
        </div>
        <div className='descriptionContent'>
          No estudies sólo leyendo. La <strong>memoria auditiva</strong> es uno de los tipos de memoria más potentes que tenemos y Recitaleyes es la plataforma que te ayuda a usarla en tu estudio: <strong>escucha y lee tus leyes o apuntes al mismo tiempo</strong> y optimiza tu estudio. Además, podrás hacerlo en casa, en el autobús o cocinando.
        </div>
        <div className='descriptionContentCentered'>
          Estudia al 100% de tu capacidad.
        </div>
        <div className={'descriptionCenteredContainer'}>
          <a className={'rcl rclSecondaryDark'} href={config.APP_SIGNUP_URL}>
            Regístrate GRATIS
          </a>
        </div>

        <div className='descriptionHeader descriptionSeparation'>
          Inteligencia Artificial para tus oídos
        </div>
        <div className='descriptionContent'>
          Creados mediante la Inteligencia Artificial más avanzada, nuestros audios ofrecen una entonación y ritmo de lectura natural y estable que <strong>te ayudará a entender y memorizar</strong>, como el subrayado de colores en los apuntes de papel.
        </div>
        <div className='descriptionContent'>
          A diferencia de una persona, nuestra IA no se cansa, así que notarás <strong>la misma calidad desde el principio hasta el final</strong>.
        </div>
        <button className={'rcl rclSecondaryDark descriptionPlayButton'} onClick={ () => this.togglePlayDemo() } aria-label="Reproducir ejemplo">
          {!this.state.loading && !this.state.playing ? <PlayIcon className={'descriptionPlayIcon'}/>  : ''}
          {this.state.loading && !this.state.playing ? <Spinner className={'descriptionPlayIcon descriptionPlayIconSpinner'}/> : ''}
          {!this.state.loading && this.state.playing ? <PauseIcon className={'descriptionPlayIcon'}/> : ''}
        </button>

        <div className='descriptionHeader descriptionSeparation'>
          Para todo tipo de exámenes
        </div>
        <div className='descriptionContent'>
          <strong>¿Examen tipo test?</strong> Mientras lees el enunciado, ¡recordarás la voz de Recitaleyes y sabrás identificar la respuesta correcta!
        </div>
        <div className='descriptionContent'>
          <strong>¿Examen de desarrollo?</strong> Mientras escribes, ¡la respuesta sonará en tu cabeza!
        </div>
        <div className='descriptionContent'>
          <strong>¿Examen oral?</strong> ¡Hasta cantarás el tema con el ritmo de Recitaleyes! <strong>¡Con facilidad, claridad y fluidez!</strong>
        </div>

        <button className={'rcl rclSecondaryDark descriptionCenteredButton'} onClick={ this.toggleLawList }>
          Ver audioleyes
        </button>

      </div>
      <LawList
        show={this.state.showLawList}
        close={this.toggleLawList}
      />
    </div>
}

export default Description