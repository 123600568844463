import React from "react"

import ContactForm from "./ContactForm"

import es from '../lang/es'
import './Faq.css'

class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: -1,
      showForm: false
    }
  }

  setActive = (active) => this.state.active === active ? this.setState({active: -1}) : this.setState({active})

  toggleContactForm = () => this.setState({showForm: !this.state.showForm})

  render = () =>
    <div id="faq" className={'mainCard cardShadow faqContainer'}>
      <div className={'faqContainerTitle'}>Preguntas frecuentes</div>
      {
        es.FAQ.map((qa, i) =>
          <div key={i} className={'faqQuestionContainer'}>
            <div onClick={() => this.setActive(i)}
              className={'faqQuestion' + (this.state.active === i ? ' faqQuestionActive' : '')}>
              <div>{qa.q}</div>
              <div className={'faqQuestionCollapseIcon'}>{ this.state.active === i ? '-' : '+' }</div>
            </div>
            <div className={'faqAnswer' + (this.state.active === i ? ' faqAnswerActive' : '')}>{qa.a}</div>
          </div>
        )
      }
    
    <div className={'faqContainerTitle contactTitle'}>¿Tienes alguna otra duda?</div>
      <div className={'contactButton'}>
        <button className={'rcl'} onClick={this.toggleContactForm}>Contacta con nosotros</button>
      </div>
      <ContactForm
        show={this.state.showForm}
        close={this.toggleContactForm}
      />
    </div>
}

export default Faq