import React from "react"

import Modal from './Modal'
import Spinner from "./Spinner"
import ContactForm from "./ContactForm"

import getLawList from '../actions/lawlist'
import es from '../lang/es'
import config from "../config"

import './LawList.css'


const dateToString = (date) => {
  return `${date.getDate()} de ${es.MONTHS[date.getMonth()]} de ${date.getFullYear()}`
}

const dateConverter = (sd) => {
  const d = sd.toString()
  const df = new Date(`${d.slice(0,4)}-${d.slice(4,6)}-${d.slice(6,8)}`)
  return dateToString(df)
}

class LawList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lawList: null,
      filter: '',
      showContact: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.show && this.props.show && !this.state.lawList)
      getLawList(this.setLawList)
    if (prevProps.show !== this.props.show) this.setState({filter: ''})
  }

  setLawList = (lawList) => this.setState({lawList})

  handleFilterChange = (event) => this.setState({ filter: event.target.value })

  toggleContact = () => this.setState({showContact: !this.state.showContact })

  lawLabel = (version, date) => {
    if (Number.isInteger(parseInt(version))) {
      // Consolidated
      return `Actualizada según la última versión oficial consolidada, de ${dateConverter(version)}`
    } else {
      // Others (dof, hash, etc.)
      return `Actualizada el ${dateToString(new Date(date))} según la última versión oficial`
    }
  }

  render = () => (
    <Modal
      show={this.props.show}
      close={this.props.close}
      title={'Normas ya disponibles'}
    >
      <ul className={'lawList'}>
        {
          this.state.lawList ?
            Object.keys(this.state.lawList).map(
              (lawId, index) =>
                this.state.lawList[lawId].name.toLowerCase().includes(this.state.filter.toLowerCase()) //filter
                && !lawId.includes('demo') // exclude demo law(s)
                  ?
                  <li key={index} className={'lawListElement'} onClick={this.props.close}>
                    <a className={'lawListLink'} href={config.APP_SIGNUP_URL} target="_blank" rel="noreferrer">
                      <div className={'lawListLawName'}>{ this.state.lawList[lawId].name }</div>
                      <div className={'lawListLawDate'}>
                        { this.lawLabel(this.state.lawList[lawId].ver, this.state.lawList[lawId].date) }
                      </div>
                    </a>
                  </li> : ''
            ) :
            <li className={'lawListSpinnerContainer'}>
              <Spinner className={'lawListSpinner'}/>
              Cargando leyes...
            </li>
        }
        <li key={'requestLaw'} className={'lawListContact'}>
          ¿No encuentras alguna norma? ¡Pídenosla pulsando <button
          className={'rcl rclSecondary lawListOpenContact'} onClick={this.toggleContact}>AQUÍ</button> y
          trataremos de tenerla disponible <strong>en menos de 72h</strong>!
        </li>
      </ul>
      <input
        className="cardInput lawListInputFilter"
        type="text"
        name="filter"
        placeholder={'Busca una norma...'}
        value={this.state.filter}
        onChange={this.handleFilterChange}
      />
      <ContactForm
        show={this.state.showContact}
        close={this.toggleContact}
        optionalFrom={true}
        subject={'Necesito una norma que no está disponible'}
        context={'home-law-form: ' + this.state.filter}
      />
    </Modal>
  )
}

export default LawList