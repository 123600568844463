import config from "../config"

const getLawList = (cb) => {
  fetch(`${config.API_URL}/lawList`,
    { headers: { Authorization: `Bearer ${config.DEMO_TOKEN}` } }
  )
    .then(res => res.json())
    .then(json => cb(json))
    .catch(() => cb(null))
}

export default getLawList