import React from "react"
import './Presentation.css'
import config from "../config"

class Presentation extends React.Component {
  render = () =>
    <div className='presentationContainer'>
      <div className='presentationSlogan'>
        Utiliza la memoria auditiva en tu oposición
      </div>
      <div className='presentationDescription'>
        Optimiza tu tiempo de estudio escuchando leyes y tus propios apuntes
      </div>
      <div className='presentationSpacer' />
      <a className={'rcl presentationCenteredButton'} href={config.APP_SIGNUP_URL}>
        Empieza GRATIS
      </a>
      <div className='presentationSpacer' />
    </div>
}

export default Presentation