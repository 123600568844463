import React from "react"

import './Modal.css'

class Modal extends React.Component {
  render = () => (
    <div className={'modalContainer' + (this.props.show ? '' : ' modalContainerDisabled')}>
      <div className={`modalContainerCard ${this.props.show ? '' : 'modalContainerCardDisabled '} ${this.props.className ? this.props.className : ''}`}>
        <div className={'modalTitle'}>{this.props.title}</div>
        <div className={'modalClose'} onClick={this.props.close} aria-label="Cerrar ventana">✕</div>
        { this.props.children }
      </div>
    </div>
  )
}

export default Modal