import React from "react"

import config from "../config"
import {ReactComponent as WhatsappIcon} from "../assets/whatsapp.svg"

import './Whatsapp.css'

class Whatsapp extends React.Component {
  render = () =>
    <div className={'whatsappContainer'}>
      <a className={'whatsappLink'} href={config.WHATSAPP} aria-label="Abrir conversación en WhatsApp">
        <WhatsappIcon className={'whatsappIcon'} />
      </a>
    </div>
}

export default Whatsapp