import React from "react"
import { ReactComponent as UserIcon } from "../assets/account-circle.svg"

import './Header.css'
import Logo from "./Logo"
import config from "../config"

class Header extends React.Component {
  render = () =>
    <div className={'headerContainer' + (this.props.scrolled ? ' headerContainerDark' : '')}>
      <Logo />
      <div className={'headerUserSection'} onClick={ () => window.location.href = config.APP_URL }>
        <button className={'rcl rclSecondary headerUserText'}>Acceder</button>
        <UserIcon className={'headerUserIcon'} alt="Icono de usuario"/>
      </div>
    </div>
}

export default Header