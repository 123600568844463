import React from "react"
import { ReactComponent as LogoIcon } from '../assets/logo.svg'
import { ReactComponent as LogoLabel } from '../assets/logo_label.svg'
import './Logo.css'

class Logo extends React.Component {
  render = () =>
    <div id={'logoContainer'}>
      <LogoIcon className={ 'logoIcon' } alt="Logo Recitaleyes"/>
      <LogoLabel className={'logoLabel'} alt="Recitaleyes"/>
    </div>
}

export default Logo