import React from "react"

import config from '../config'
import { contact } from '../actions/contact'

import lang from "../lang/es"

import './ContactForm.css'

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subject: this.props.subject ? this.props.subject : '',
      from: this.props.from ? this.props.from : '',
      message: this.props.message ? this.props.message : '',

      working: false,
      sent: false,
      sentError: null
    }
  }

  handleSubjectChange = (event) => this.setState({ subject: event.target.value })
  handleFromChange = (event) => this.setState({ from: event.target.value })
  handleMessageChange = (event) => this.setState({ message: event.target.value })

  sendResult = (result) => {
    const sentError = result ?
      null
      :
      <div>
        Error al enviar el mensaje. Puedes intentarlo de nuevo o escribir directamente a <a href={`mailto:${config.CONTACT_EMAIL}`}>{config.CONTACT_EMAIL}</a>.
      </div>

    this.setState({sentError, sent: true, working: false})
  }
  send = () => {
    if (!this.state.subject)
      this.setState({ sentError: 'Debes indicar un asunto para tu mensaje.', sent: true, working: false })
    else if (!this.props.optionalFrom && !this.state.from)
      this.setState({ sentError: 'Debes indicar una dirección de correo electrónico para que podamos contactar contigo si es necesario.', sent: true, working: false })
    else if (!this.state.message)
      this.setState({ sentError: 'Debes escribir contenido en tu mensaje.', sent: true, working: false })
    else if (this.state.message.length >= config.MAX_CONTACT_MESSAGE_LENGTH)
      this.setState({ sentError: `Tu mensaje no puede tener más de ${config.MAX_CONTACT_MESSAGE_LENGTH} caracteres.`, sent: true, working: false })
    else {
      contact(
        JSON.stringify({
          subject: this.state.subject,
          from: this.state.from,
          message: this.state.message,
          context: this.props.context
        }),
        this.sendResult
      )
      this.setState({working: true})
    }
  }

  retry = () => this.setState({sent: false, sentError: null})

  close = () => {
    this.setState({
      subject: this.props.subject ? this.props.subject : '',
      from: this.props.from ? this.props.from : '',
      message: this.props.message ? this.props.message : '',

      working: false,
      sent: false,
      sentError: null
    })
    this.props.close()
  }

  render = () =>
    <div className={'modalContainer contactFormContainer' + (this.props.show ? '' : ' modalContainerDisabled' )}>
      <div className={'modalContainerCard' + (this.props.show ? '' : ' modalContainerCardDisabled' )}>
        <div className={'modalTitle'}>{`Formulario de contacto`}</div>
        <div className={'modalClose'} onClick={this.close}>✕</div>

        <input className="contactFormSubject" type="text" name="subject" placeholder={ 'Asunto de la consulta' }
          value={ this.state.subject } onChange={ this.handleSubjectChange }/>
        <input className="contactFormFrom" type="text" name="from" placeholder={`${this.props.optionalFrom ? '(Opcional) ' : ''} Tu dirección de email (para que podamos responderte)`}
               value={ this.state.from } onChange={ this.handleFromChange }/>
        <textarea className="contactFormMessage" name="message" placeholder={ 'Tu consulta' }
               value={ this.state.message } onChange={ this.handleMessageChange }/>

        <div className={'contactFormLegalFirstLayer'}>
          { lang.LEGAL_LOPD_FIRST_LAYER_CONTACT }<a href={'/privacy.pdf'}>política de privacidad</a>.
        </div>

        {!this.state.working ?
          <div className={'contactFormButtons'}>
            <button className="rcl rclSecondary" onClick={this.close}>
              {'Cancelar'}
            </button>
            <button className="rcl" onClick={this.send}>
              {'Enviar'}
            </button>
          </div>
          :
          <div className={'contactFormSending'}>Enviando...</div>
        }

        <div className={'contactFormSent' + (this.state.sent ? ' contactFormSentShow' : '')}>
          {this.state.sentError ?
            <div className={'contactFormSentResultContainer'}>
              <div className={'koLogo'}>✕</div>
              <div className={'contactFormSentMsg'}>{this.state.sentError}</div>
              <button className="rcl" onClick={this.retry}>{'Volver'}</button>
            </div>
            :
            <div className={'contactFormSentResultContainer'}>
              <div className={'okLogo'}>✓</div>
              <div className={'contactFormSentMsg'}>¡Listo! Mensaje enviado correctamente.</div>
              <button className="rcl" onClick={this.close}>{'Volver'}</button>
            </div>
          }
        </div>
      </div>
    </div>
}

export default ContactForm